// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("@nathanvda/cocoon")

global.toastr = require("toastr")
var Inputmask = require('inputmask');

import 'jquery'
// import "argon/bootstrap/dist/js/bootstrap.bundle.min"
import "argon/js-cookie/js.cookie"
import "argon/jquery.scrollbar/jquery.scrollbar.min"
import "argon/jquery-scroll-lock/dist/jquery-scrollLock.min"
import "argon/js/argon"